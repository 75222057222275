/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * reelgood.com.au
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the *_subsetted.html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
    font-family: "BrauerNeue-Bold";
    src:url("../fonts/lineto-brauerneue-bold.eot");
    src:url("../fonts/lineto-brauerneue-bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/lineto-brauerneue-bold.woff2") format("woff2"),
        url("../fonts/lineto-brauerneue-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}


/* Subsetted */

@font-face {
    font-family: "BrauerNeue-Bold-S";
    src:url("../fonts/fonts_subsetted/lineto-brauerneue-bold.eot");
    src:url("../fonts/fonts_subsetted/lineto-brauerneue-bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/fonts_subsetted/lineto-brauerneue-bold.woff2") format("woff2"),
        url("../fonts/fonts_subsetted/lineto-brauerneue-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
