body.term-top-picks {
  .nav-secondary {
    .active.menu-all-reviews a {
      color: $black;
      &::before {
        background-color: $black;
      }
    }
  }
}
