// Grid system
.main {
  @include make-col-ready();
  @media (min-width: 769px) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
  position: static;
}
.sidebar {
  @include make-col-ready();
  @media (min-width: 769px) {
    @include make-col($sidebar-sm-columns);
  }
}
