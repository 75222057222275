body > .wrap {
  min-height: 640px;
  padding-top: 80px;
  padding-bottom: 64px;
  @include padding;
  background-color: $beige;
}
@include media-breakpoint-down(md) {
  body > .wrap {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

body.noscroll {
  overflow: hidden;
}
