body.page-template-template-authors,
body.author {
  section.author {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;

    div.meta {
      flex: 1.5 0 0;

      img.avatar {
        @extend .img-fluid;
        max-width: 168px;
        margin-bottom: 16px;
        border-radius: 50%;
      }

      h1,h2 {
        font-size : 36px;
        line-height : 40.5px;
        letter-spacing : -0.18px;
      }

      h1 {
        margin: 0;
      }

      h2 {
        margin-bottom: 16px;
        color: $purple;
      }

      a.contact-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 24px;

        img {
          height: 17.5px;
        }

        &:hover {
          opacity: 0.7;
        }

        &::after {
          content: "Contact";
          display: inline-block;
          margin-left: 6px;
          line-height: 18.7px;
          letter-spacing: 0.28px;
        }
      }
    }
    div.biography {
      flex: 2.5 0 0;
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing : -0.16px;
      }
    }
  }

  .card-deck {
    margin-bottom: 0;

    article.card {
      margin-bottom: 16px;
    }
  }

  .author-link {
    text-align: right;
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $purple;
    }
  }
}

@include media-breakpoint-down(md) {
  body.page-template-template-authors {
    .card-deck {
      article.card:last-child {
        display: none;
      }
    }
    .author-link {
      margin-bottom: 48px;
    }
  }
}

@include media-breakpoint-down(sm) {
  body.page-template-template-authors {
    section.author {
      flex-direction: column;
      margin-bottom: 24px;

      div.meta {
        margin-bottom: 32px;
      }
    }

    .card-deck {
      article.card:not(:first-child) {
        display: none;
      }
    }

    .author-link {
      position: relative;
      text-align: center;
      padding-bottom: 24px;
      margin-bottom: 48px;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -24px;
        height: 4px;
        width: calc(100% + 48px);
        background-color: $white;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
