body.home,
body.blog,
body.archive,
body.search,
body.page-template-template-authors {

  article.card {
    position: relative;
    flex-basis: 30%;
    margin-bottom: 64px;

    @include media-breakpoint-up(md) {
      max-width: 48%;
    }
    @include media-breakpoint-up(lg) {
      max-width: 32%;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 46%;
    }

    .card-header {
      position: relative;
      padding: 0;
      background-color: darken($beige, 10%);
    }

    a.card-img-top {
      display: block;
      max-width: 100%;
      height: 185px;
      overflow: hidden;
      @include bgImage;
      filter: grayscale(100%);
      opacity: 0.7;
      transition: all 0.2s ease;
      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }

    .video-container {
      @include video_container;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover .video-thumbnail {
        filter: grayscale(0);
        opacity: 1;
      }

      > a.play-button {
        z-index: 99;

        > .icon-play::before {
          color: $purple;
          font-size: 36px;
        }
      }

      > .video-thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include bgImage;
        filter: grayscale(100%);
        opacity: 0.7;
        transition: all 0.2s ease;
      }

      iframe {
        display: none;
      }
    }

    &.editors-choice div.ec-button-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      overflow: hidden;
      border-radius: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;

      &:hover + a.card-img-top {
        filter: grayscale(0);
        opacity: 1;
      }
    }

    &.editors-choice a.ec-button {
      position: relative;
      display: inline-block;
      width: 0;
      height: 32px;
      line-height: 32px;
      margin: 0 20px;
      white-space: nowrap;
      text-overflow: clip;

      font-family: $headings-font-family;
      color: $purple;
      font-size: 26px;
      background-color: $white;
      border-radius: 2px;

      &::before {
        content: "";

        position: absolute;
        top: 0;
        top: -4px;
        left: -20px;

        display: inline-block;
        width: calc(100% + 40px);
        height: calc(100% + 8px);
        background-color: $purple;
        border-radius: 50px;
        z-index: -1;
      }
    }

    .card-block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: calc(100% - 185px);
      padding-top: 64px;
      padding-bottom: 16px;
    }
    .category {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      @include make-row();
      align-items: stretch;
      margin: 0;

      .post-type,
      .post-term {
        padding: 4px 8px;
        @include make-col(6);
        min-height: 42px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 300;
        font-size: 12px;
        line-height: 14.96px;
        text-align: center;
      }

      .post-type {
        background-color: $light-grey;
        text-transform: capitalize;
      }
      .post-term {
        background-color: #fff;
      }
    }
    .entry-meta {
      margin-bottom: 4px;
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 0.04em;

      a {
        color: $text-grey;
      }
    }

    h2.card-title {
      font-size: 26px;
      line-height: 28.8px;
    }

    .card-text {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 21px;

      a.readmore {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  body.home,
  body.blog,
  body.archive,
  body.search,
  body.page-template-template-authors {

    article.card {
      margin-bottom: 32px;
    }
  }
}

body.post-type-archive {

  article.card {
    .category {
      .post-type {
        color: $purple;
      }
    }
  }
}

body.term-rgff,
body.tax-review_categories,
body.tax-article_categories,
body.tax-video_categories,
body.tax-podcast_categories {

  article.card {
    .category {
      .post-term {
        color: $purple;
      }
    }
  }
}

.posts-navigation .nav-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: $black;

  .nav-previous,
  .nav-next,
  .nav-random {
    z-index: 1000;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-previous a,
  .nav-next a,
  .nav-random a {
    position: relative;
    display: inline-block;
    margin: 0 24px;
    padding: 0 8px;

    background-color: $black;
    border-radius: 2px;

    > i,
    > span {
      display: none;
    }

    &::after {
      display: inline-block;
      font-family: $headings-font-family;
      font-size: 26px;
      color: $white;
      line-height: 31px;
    }

    &::before {
      content: "";

      position: absolute;
      top: 0;
      top: -4px;
      left: -16px;

      display: block;
      width: calc(100% + 32px);
      height: calc(100% + 8px);
      background-color: #fff;
      border-radius: 50px;
      z-index: -1;
    }

    &:hover,
    &:focus {
      > span.question-mark {
        color: $purple;
      }
      &::after,
      > i {
        color: $purple;
      }
      &::before {
        background-color: $purple;
      }
    }
  }
  .nav-previous a::after {
    content: "Previous Page";
  }
  .nav-random a::after {
    content: "Random Post";
  }
  .nav-next a::after {
    content: "Next Page";
  }
}

@include media-breakpoint-down(sm) {
  .posts-navigation .nav-links {

    .nav-previous a,
    .nav-next a,
    .nav-random a {
      &::after {
        display: none;
      }
    }

    .nav-previous a > i,
    .nav-next a > i,
    .nav-random a > span.question-mark {
      display: block;
      font-size: 26px;
      color: $white;
    }

    .nav-next a > i {
      transform: scaleX(-1);
    }

    .nav-previous a > i,
    .nav-next a > i {
      display: flex;
      height: 39px;
      align-items: center;

      &::before {
        margin-left: 0;
        margin-right: 4px;
        font-size: 16px;
      }
    }

    .nav-random a > span.question-mark {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
