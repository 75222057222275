footer.content-info {
  margin-left: auto;
  margin-right: auto;

  .footer-connect,
  .footer-tags {
    max-width: none;
    text-align: center;
    background-color: $light-grey;
  }

  .footer-connect {
    padding-top: 56px;
    padding-bottom: 56px;

    h1 {
      margin-bottom: 24px;
      font-size : 26px;
      color: $white;
    }

    .mc4wp-form {

      .mc4wp-form-fields {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include media-breakpoint-down(xs) {
          flex-direction: column;
        }

        input {
          height: 36px;
          border-radius: 50px;
          text-align: center;
          appearance: none;
          outline: none;
        }

        input[type="text"],
        input[type="email"] {
          min-width: 240px;
          margin-right: 8px;
          border-color: transparent;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $light-grey;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: $light-grey;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: $light-grey;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: $light-grey;
          }
        }

        input[type="submit"] {
          padding-left: 24px;
          padding-right: 24px;
          color: $white;
          background-color: transparent;
          border: 1px solid #fff;

          &:hover {
            color: $purple;
            border-color: $purple;
          }
        }
      }
    }

    .social-menu {

      a {
        > i::before {
          color: $white;
          font-size: 36px;
        }

        &:hover > i::before {
          color: $purple;
        }
      }
    }
  }

  .footer-tags ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    max-width: 960px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 8px;

    li {
      position: relative;
      z-index: 1000;
      padding-bottom: 16px;
    }

    li a {
      position: relative;
      margin: 0 24px;
      padding: 0 8px;

      font-family: $headings-font-family;
      font-size: 26px;
      color: $red;
      background-color: $white;
      border-radius: 0;

      &::before {
        content: "";

        position: absolute;
        top: -4px;
        left: -16px;

        display: block;
        width: calc(100% + 32px);
        height: calc(100% + 8px);
        background-color: $red;
        border-radius: 0;
        z-index: -1;
      }

      &:hover,
      &:focus {
      color: $purple;

        &::before {
          background-color: $purple;
        }
      }
    }
  }

  .footer-bottom {
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
    background-color: $beige;

    p {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      font-size: 11.2px;
      line-height: 16.56px;
      letter-spacing: 0.16px;
    }

    p.contact-cta {
      margin-bottom: 4px;
      font-size: 12.8px;
      line-height: 20px;
      letter-spacing: -0.12px;
      color: $black;
      a {
        color: $purple;
      }
    }
  }
}
