body.single {
  main .entry-content {
    font-size: 16px;
    line-height: 24px;
    letter-spacing : -0.16px;

    h1,h2,h3,h4,h5,h6 {
      font-size: 26px;
      line-height: 28.8px;
      letter-spacing : -0.13px;
    }

    p {
      text-align: left!important;
    }

    a {
      font-weight: 700;
    }

    img {
      @extend .img-fluid;
      width: 100%;
    }

    iframe {
      max-width: 100%;
    }

    .wp-caption-text {
      font-weight: 300;
      margin-top: 12px;
      margin-bottom: 24px;
      font-size : 14px;
      line-height : 20.7px;
      letter-spacing : 0.21px;
      color : #808080;
    }
  }

  footer .review-rating p {
    font-family: $headings-font-family;
    margin-top: 80px;
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 40.5px;
    letter-spacing: -0.18px;
  }

  .posts-navigation .nav-links {
    .nav-previous a::after {
      content: "Previous Post";
    }
    .nav-next a::after {
      content: "Next Post";
    }
  }
}
