.tag {
  display: inherit;
  padding: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-align: inherit;
  white-space: inherit;
  vertical-align: inherit;
}
