body.home {
  .posts-navigation {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 64px;
    @include media-breakpoint-down(md) {
      margin-top: 48px;
    }
  }
  footer {
    margin-top: 71px;
    @include media-breakpoint-down(sm) {
      margin-top: 79px;
    }
  }
}
