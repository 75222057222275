body.single {
  aside.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
      h1.entry-title,
      .entry-meta > h2,
      .entry-meta time {
        font-family: $headings-font-family;
        font-size : 36px;
        line-height : 40.5px;
        letter-spacing : -0.18px;
      }

      h1.entry-title {
        color: $purple;
      }
    }

    .addtoany_list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 16px;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 24px;

        &:hover::after {
          color: $purple;
        }
      }

      a::after {
        display: inline-block;
        margin-left: 6px;
        line-height: 18.7px;
        letter-spacing: 0.28px;
      }

      a.a2a_button_twitter {
        img {
          height: 18.5px;
        }

        &::after {
          content: "Tweet";
        }
      }

      a.a2a_button_facebook {
        img {
          height: 20.9px;
        }

        &::after {
          content: "Share";
        }
      }

      a.a2a_button_email {
        img {
          height: 17.5px;
        }

        &::after {
          content: "Mail";
        }
      }
    }

    .session-times {
      margin-top: 24px;
      position: relative;
      z-index: 1000;
      padding-bottom: 16px;

      a {
        position: relative;
        margin-left: 16px;
        padding: 0 8px;

        font-family: $headings-font-family;
        font-size: 26px;
        color: $black;
        background-color: $beige;
        border-radius: 0;

        &::before {
          content: "";

          position: absolute;
          top: -4px;
          left: -16px;

          display: block;
          width: calc(100% + 32px);
          height: calc(100% + 8px);
          background-color: $black;
          border-radius: 0;
          z-index: -1;
        }

        &:hover,
        &:focus {
        color: $purple;

          &::before {
            background-color: $purple;
          }
        }
      }
    }

    p.article-excerpt {
      font-family: $headings-font-family;
      font-size: 36px;
      line-height: 40.5px;
      letter-spacing: -0.18px;

      &:first-of-type {
        transform: translateY(-100%);
      }

      &:last-of-type {
        transform: translateY(-150%);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  body.single {
    aside.sidebar {
      margin-bottom: 42px;

      p.article-excerpt {
        margin-top: 42px;
        margin-bottom: -16px!important;
        transform: none!important;

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
