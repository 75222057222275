body.search {
  nav.nav-primary,
  nav.nav-secondary {

    .nav li.active a {
      color: $black;

      &::before {
        background-color: $black;
      }
    }
  }

  form.searchform {
    display: block;
  }

  .wrap {
    padding-top: 64px;
  }

  .page-header h1 {
    margin-bottom: 32px;
  }
}
