// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$white: #ffffff;
$light-grey: #DCDCDC;
$black: #1A1A1A;
$beige: #FFF4EB;
$purple: #9100FF;
$red: #FF6478;

$text-grey: #808080;

$brand-primary:         $purple;


// Body
//
// Settings for the `<body>` element.

$body-color: $black;

// Typography

$font-family-sans-serif: "BrauerNeue-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: "Roboto Slab", Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-serif;

$headings-font-family:   $font-family-sans-serif;
$headings-font-weight:   400;
$headings-color: $black;

$font-size-root: 14px;

// Links
//
// Style anchor elements.

$link-color:            $black;
$link-hover-color:      $link-color;
$link-hover-decoration: none;


// Components
//
// Define common padding and border radius sizes and more.

$border-radius:          0px;

// Cards
$card-spacer-x: 24px;
$card-spacer-y: 24px;
$card-border-width: 0px;
$card-border-radius: 0px;
$card-border-radius-inner: 0px;
$card-link-hover-color: $link-hover-color;
$card-deck-margin: 8px;
