#slb_viewer_wrap .slb_theme_slb_default .slb_data_title span {
  font-family: $font-family-base;
  font-weight: 300;
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20.7px;
  letter-spacing: .21px;
  color: gray;
}
