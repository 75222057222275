/*-----------------------------------*/
/*  Margins & Padding
/*-----------------------------------*/

@mixin padding {
    padding-left: 64px;
    padding-right: 64px;

    @include media-breakpoint-down(md) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@mixin margins {
    margin-left: 64px;
    margin-right: 64px;

    @include media-breakpoint-down(xs) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

/*-----------------------------------*/
/*  Responsive items
/*-----------------------------------*/

@mixin video_container {
	position: relative;
    height: 185px;
    overflow: hidden;
    max-width: 100%;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin bgImage {
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin bgIcon {
	background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

/*-----------------------------------*/
/*  Animation
/*-----------------------------------*/

$defaultTiming: opacity 0.2s ease;
$defaultHoverOpacity: 0.7;

@mixin basicHoverState {
    cursor: pointer;
	transition: $defaultTiming;

	&:hover {
		opacity: $defaultHoverOpacity;
	}
}
