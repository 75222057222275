body.error404 {
  form.searchform {
    display: block;
  }

  .wrap {
    padding-top: 64px;
  }

  .page-header h1 {
    margin-bottom: 32px;
  }
}
