header.banner {
  padding-top: 64px;
  background-color: $light-grey;

  .container {
    text-align: center;
  }

  a.brand {
    display: inline-block;
    width: 350px;
    height: 80px;
    margin-bottom: 24px;

    @include bgIcon;
    background-image: url(../images/reelgood-logo.svg);
  }
}

nav.nav-primary,
nav.nav-secondary {

  .nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      z-index: 1000;
      padding-bottom: 16px;
    }

    li.active a {
      color: $purple;

      &::before {
        background-color: $purple;
      }
    }

    li a {
      position: relative;
      margin: 0 21px;
      padding: 0 8px;

      font-family: $headings-font-family;
      font-size: 26px;
      background-color: $light-grey;
      border-radius: 2px;

      &::before {
        content: "";

        position: absolute;
        top: 0;
        top: -4px;
        left: -16px;

        display: block;
        width: calc(100% + 32px);
        height: calc(100% + 8px);
        background-color: $black;
        border-radius: 50px;
        z-index: -1;
      }

      &:not(.filter-btn):not(.filter-btn-sub):hover,
      &:not(.filter-btn):not(.filter-btn-sub):focus {
      color: $purple;

        &::before {
          background-color: $purple;
        }
      }
    }
  }
}

.filter-btn,
.filter-btn-sub {
  &.active {
    color: $purple;
    &::before {
      background-color: $purple!important;
    }
  }
}


.mobile-nav-header {
  display: none;
  flex-direction: row;
  background-color: $white;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    padding-bottom: 8px;
    background-color: $beige;
  }

  button.hamburger,
  .selected-sub-category {
    flex: 1 0 0;
  }

  button.hamburger {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    min-height: 50px;
    background-color: $light-grey;
    > .i {
      color: $black;
    }
  }

  .selected-sub-category {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }

  button.hamburger,
  .selected-sub-category {
    font-family: $headings-font-family;
    font-size: 21px;
    line-height: 21px;
  }
}

nav.nav-primary,
.mobile-nav-footer {
  display: flex;
  justify-content: center;
  .search-menu-item,
  .contact-menu-item,
  .clear,
  .return,
  .apply {
    height: 39px;
    display: flex;
    align-items: center;
    color: $black;
    &.active {
      color: $purple;
    }
    &:hover {
      color: $purple;
    }
  }
  button.search-menu-item {
    background: none;
    border: none;
    outline: none;
    padding: 0;
  }
}

.secondary-header {
  text-align: center;
  &.filter-prompt-visible {
    h2.filter-prompt {
      display: block;
    }
  }
}

nav.nav-secondary {
  padding-top: 16px;
  padding-bottom: 8px;

  .nav {
    display: none;

    &.visible {
      display: flex;
    }

    li a {
      background-color: #fff;
    }
  }
}

form.searchform {
  display: none;
  margin-top: 8px;
  margin-bottom: 16px;

  input.search-submit {
    padding: 0;
    line-height: 1;
    background: none;
    border: none;
    outline: none;
    font-family: $headings-font-family;
    font-size: 26px;
    &:hover,
    &:focus {
      color: $purple;
    }
  }

  input.search-field {
    width: calc(100% - 2px);
    margin-left:1px;
    padding: 4px 6px 0;
    outline: none;
    border: 0;
    box-shadow: -7px 10px 0px -7px $black, 7px 10px 0px -7px $black;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    &:focus {
      outline: none;
      box-shadow: -7px 10px 0px -7px $purple, 7px 10px 0px -7px $purple;
    }
  }
}


h2.filter-prompt {
  display: none;
  color: $light-grey;
  margin-bottom: 0;
  padding: 8px 0 16px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}


.secondary-header .mobile-nav-footer {
  display: none;
  margin-top: 24px;
  padding: 4px 16px;
  background-color: $black;
  justify-content: space-between;

  .search-menu-item,
  .contact-menu-item,
  .clear,
  .return,
  .apply {
    position: relative;
    color: $white;
    height: 50px;

    &::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -32px;
      color: $black;
      font-weight: 300;
      font-size: 12px;
    }

    > i::before {
      font-size: 28px;
    }

    &.active {
      color: $purple;
      &::before {
        color: $purple;
      }
    }
  }
  .clear::before {
    content: "Clear";
  }
  .contact-menu-item::before {
    content: "Writers";
  }
  .search-menu-item::before {
    content: "Search";
  }
  .return::before {
    content: "Return";
  }
  .apply::before {
    content: "Apply";
  }


  .clear {
    display: none;
    > i::before {
      font-size: 20px;
    }
  }
  .return > i::before {
    font-size: 20px;
  }
  .apply > i::before {
    font-size: 20px;
  }
  .random {
    position: relative;
    margin-top: 8px;
    z-index: 1000;
    &::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -40px;
      content: "Random";
      color: $black;
      font-weight: 300;
      font-size: 12px;
    }
  }
  .random-link {
    position: relative;
    display: inline-block;
    margin: 0 18px;
    padding: 0 8px;

    background-color: $black;
    border-radius: 2px;
    line-height: 32px;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      top: -4px;
      left: -16px;

      display: block;
      width: calc(100% + 32px);
      height: calc(100% + 8px);
      background-color: #fff;
      border-radius: 50px;
      z-index: -1;
    }

    > span.question-mark {
      display: block;
      font-size: 26px;
      color: $white;
      padding-left: 4px;
      padding-right: 4px;
    }

    &:focus {
      > span.question-mark {
        color: $purple;
      }
      &::before {
        background-color: $purple;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  header.banner {
    padding-top: 32px;
  }
}

@include media-breakpoint-down(sm) {

  .mobile-nav-wrap.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background-color: #fff;
    overflow-y: auto;
    max-width: 100vw;
    a.brand,
    .mobile-nav-header {
      display: none;
    }
  }

  header.banner {
    background-color: $black;
    padding-top: 0;

    .container {
      padding: 0;
    }

    a.brand {
      width: 240px;
      height: 55px;
      margin-top: 16px;
      margin-bottom: 16px;
      background-image: url(../images/reelgood-logo-white.svg);
    }
  }

  .secondary-header {
    padding-left: 0;
    padding-right: 0;

    @media (max-height: 568px) {
      &.open .mobile-nav-footer {
        margin-top: 12px;
        .contact-menu-item::before,
        .random::before,
        .search-menu-item::before {
          display: none;
        }
      }
      &.open nav.nav-secondary {
        min-height: calc(100vh - 277px - 70px); // full device height - header.banner - mobile-nav-footer
      }
    }
  }

  .mobile-nav-header,
  .secondary-header .mobile-nav-footer {
    display: flex;
  }

  nav.nav-primary,
  .secondary-header {
    display: none;
  }

  nav.nav-primary,
  .secondary-header {
    .nav {
      flex-direction: column;
    }
  }

  nav.nav-primary .nav li,
  nav.nav-secondary .nav li {
    padding-bottom: 10px;
  }

  nav.nav-primary {
    padding-top: 24px;
    background-color: $light-grey;

    .nav li:last-child {
      padding-bottom: 18px;
    }

    > .search-menu-item,
    > .contact-menu-item {
      display: none;
    }
  }

  nav.nav-secondary {
    min-height: calc(100vh - 277px - 82px); // full device height - header.banner - mobile-nav-footer
  }

}
